<template>
    <div></div>
    <!-- <div class="wuyou-waiter">
      <ul class="waiter-ul">
        <li class="wuyou-shop">
          <a class="fa fa-volume-control-phone" href="#"></a>
          <div class="waiter-title">19108222397</div>
        </li>
        <li class="wuyou-contact">
          <a class="fa fa-weixin" href="#"></a>
          <div class="waiter-title1">
            <img src="../../assets/img/logos/lengWechat.png" alt="微信二维码" />
          </div>
        </li>
        <li class="wuyou-top">
          <a class="fa fa-qq" href="#"></a>
          <div class="waiter-title1">
            <img src="../../assets/img/logos/lengQQ.jpg" alt="" />
          </div>
        </li>
      </ul>
    </div> -->
</template>
<script>
import $ from 'jquery'

export default {
    methods: {}
}
</script>
<style lang="less" scoped>
img {
    width: 100%;
    height: 100%;
}

ul {
    list-style: none;
}

.wuyou-waiter {
    position: fixed;
    left: 17px;
    bottom: 10%;
    margin-top: -90px;
    width: 60px;
    box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.3);
    z-index: 999;
    // background: #4879FE;
    background-image: linear-gradient(to top, #48c6ef 0%, #6f86d6 100%);
}

.waiter-ul li {
    position: relative;
    height: 60px;
    line-height: 60px;
    border-bottom: 1px solid #fff;
}

.waiter-ul li a {
    position: absolute;
    left: 0;
    top: 0;
    z-index: 2;
    color: #fff;
    display: block;
    width: 60px;
    height: 60px;
    line-height: 60px;
    text-align: center;
    -webkit-transition: all 0.6s;
    -ms-transition: all 0.6s;
    -moz-transition: all 0.6s;
    text-decoration: none;
    font-size: 24px;
}

.waiter-title {
    position: absolute;
    left: 0px;
    bottom: 1px;
    color: #fff;
    width: 110px;
    height: 40px;
    line-height: 40px;
    text-align: center;
    -webkit-transition: all 0.6s;
    -ms-transition: all 0.6s;
    -moz-transition: all 0.6s;
    background-color: #fff;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.3);
    z-index: 1;
    opacity: 0;
}

.waiter-title:before {
    content: '';
    display: block;
    width: 10px;
    height: 10px;
    position: absolute;
    left: -5px;
    top: 14px;
    background-color: #fff;
    transform: rotate(45deg);
}

.waiter-ul li:hover .waiter-title {
    left: 80px;
    opacity: 1;
}

.waiter-title1 {
    position: absolute;
    left: 0px;
    bottom: -40px;
    padding: 5px;
    color: #fff;
    width: 160px;
    height: 160px;
    line-height: 40px;
    text-align: center;
    -webkit-transition: all 0.6s;
    -ms-transition: all 0.6s;
    -moz-transition: all 0.6s;
    background-color: #fff;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.3);
    z-index: 1;
    opacity: 0;
}

.waiter-title1:before {
    content: '';
    display: block;
    width: 10px;
    height: 10px;
    position: absolute;
    left: -5px;
    top: 50%;
    background-color: #fff;
    transform: rotate(45deg);
}

.waiter-ul li a:hover {
    background: coral !important;
}

.waiter-ul li:hover .waiter-title1 {
    left: 80px;
    opacity: 1;
}

.waiter-ul li.wuyou-shop .waiter-title,
.waiter-ul li.wuyou-contact .waiter-title1,
.waiter-ul li.wuyou-top .waiter-title {
    background-color: rgba(0, 0, 0, 0.7);
    color: coral;
}

.waiter-ul li.wuyou-shop .waiter-title:before,
.waiter-ul li.wuyou-contact .waiter-title1:before,
.waiter-ul li.wuyou-top .waiter-title:before {
    background-color: rgba(0, 0, 0, 0.7);
}

/* 小屏幕（平板，大于等于 768px） */
@media (max-width: 768px) {
    .wuyou-waiter {
        height: 46rem;
        width: 15rem;
        bottom: 9%;

        .waiter-ul {
            li {
                height: 15rem;
                width: 15rem;
                // position: absolute;

                a {
                    left: 3.1875rem;
                    top: 5.375rem;
                    font-size: 7.5rem;
                    position: absolute;
                }

                // a:hover {
                //   position:absolute;
                //   left: -0.1875rem;
                //   top: 0.375rem;
                //   width: 15rem;
                //   height: 15rem;
                //   font-size: 7.5rem;
                // }
            }
        }

        .waiter-title {
            width: 0rem;
            height: 0rem;
            //  left: 11.6875rem;
            //  top: -7.125rem;

        }

        .waiter-title:before {
            // width: 5.625rem;
            // height: 5.625rem;
        }

        .waiter-ul li:hover .waiter-title {
            left: 16.5rem;
            top: 6.5rem;
            font-size: 5.5rem;
        }

        .waiter-title1 {
            width: 16.625rem;
            height: 16.625rem;
        }

        .waiter-title1:before {
            // width: 4.625rem;
            // height: 4.625rem;
            // // left: 1rem;
            // top: 6rem;
            // position:absolute;
        }

        .waiter-ul li a:hover {
            // position:relative;
            // left: -0.1875rem;
            // top: 0.375rem;
            width: 0rem;
            height: 0rem;
            // font-size: 7.5rem;
            // background: rgb(91, 142, 197) !important;
        }

        .waiter-ul li:hover .waiter-title1 {
            left: 16rem;
            top: -1rem;
        }
    }

    img {
        // padding-left: 12rem;
        width: 100%;
        height: 100%;
    }
}
</style>
