<template>
    <div class="bottom_container">
        <div class="bottom_topContent">
            <div class="topContent_center">
                <dl>
                    <dt>产品介绍</dt>
                    <dd v-for="item in cloudList" :key="item.id" @click="clickBottomMenu(item)">
                        <a href="javascript:;">{{ item.name }}</a>
                    </dd>
                </dl>

                <dl>
                    <dt>客户案例</dt>
                    <dd
                        v-for="item in serviceSupportList"
                        :key="item.id"
                        @click="clickBottomMenu(item)">
                        <a :href="item.url" target="_blank">{{ item.name }}</a>
                    </dd>
                </dl>

                <dl>
                    <dt>关于我们</dt>
                    <dd v-for="item in aboutKmmList" :key="item.id" @click="clickBottomMenu(item)">
                        <a href="javascript:;">{{ item.name }}</a>
                    </dd>
                </dl>
            </div>

            <div class="topContent_left">
                <h4>成都微见科技有限公司</h4>
                <div class="d1">
                    <p class="p1">咨询热线</p>
                    <small class="sm1" style="font-weight:normal">18628976347（郑先生）</small>
                    <br />
                    <small class="sm1" style="font-weight:normal">{{ emailPhone.phone }}</small>
                </div>
                <div class="d2">
                    <p class="p2">邮箱</p>
                    <small class="sm2">zhengzhou@microknown.com</small>
                    <br />
                    <small class="sm2">{{ emailPhone.email }}</small>
                </div>
                <div class="d3">
                    <p class="p2">地址</p>
                    <small class="sm2">{{ emailPhone.address }}</small>
                </div>
            </div>
            <div class="topContent_right">
                <div class="topContent_img" v-for="item in codeList" :key="item.id">
                    <img :src="item.path" :alt="item.name" :title="item.name"/>
                    <br />
                    <small style="color: #fff">{{ item.name }}</small>
                </div>
            </div>
        </div>
        <div class="bottom_bottomContent">
            <p>
                Copyright © 成都微见科技有限公司，2019-2024
                <a target="_blank" href="https://beian.miit.gov.cn/"> 蜀ICP备19022061号-1</a>
            </p>
        </div>
    </div>
</template>

<script>
export default {
    inject: ['reload'],
    props: ['emailPhoneInfo', 'codeInfo'],

    mounted() {
        this.initData()
    },

    data() {
        return {
            aboutKmmList: [
                {
                    id: '1',
                    name: '了解我们',
                    component: 'Aboutus'
                },
                {
                    id: '3',
                    name: '联系我们',
                    component: 'Immediately'
                }
            ],

            cloudList: [
                {
                    id: '5',
                    name: '低代码平台',
                    component: 'ProductLowCode'
                },
                {
                    id: '6',
                    name: '大数据平台',
                    component: 'ProductBigData'
                },
                {
                    id: '7',
                    name: '进销存',
                    component: 'ProductErp'
                },
                {
                    id: '8',
                    name: '小工单生产管理',
                    component: 'ProductTask'
                },
                {
                    id: '9',
                    name: 'WMS',
                    component: 'ProductWms'
                },
                {
                    id: '10',
                    name: 'MES',
                    component: 'ProductMes'
                },
                {
                    id: '11',
                    name: '工业物联网',
                    component: 'ProductIot'
                },
                {
                    id: '12',
                    name: 'OA办公',
                    component: 'ProductOa'
                },
            ],

            serviceSupportList: [
                {
                    id: '38',
                    name: 'x机加进销存和生产管理',
                    component: 'CaseErpTask'
                },
                 {
                    id: '40',
                    name: 'x制药企业库存管理',
                    component: 'wmsCase'
                },
                {
                    id: '41',
                    name: 'x集团日常办公管理',
                    component: 'oaCase'
                },
                {
                    id: '39',
                    name: 'x光纤制造OTDR测试系统',
                    component: 'otdrCase'
                },
            ],

            suloteList: [
                {
                    id: '30',
                    name: 'MES',
                    component: 'PLC'
                },
            ],

            canvasHgh: 420,
            emailPhone: {phone: '15858264060（唐先生）', email: 'tangzhi@microknown.com' ,address: '四川省成都市郫都区创智南一路66号绿地盈创2栋2209室'},
            codeList: [
                {name: '公众号', path: require('@/assets/img/other/eqCode3.jpg')},
                // { name: '小程序', path: require('@/assets/img/other/eqCode1.jpg') },
                // { name: '客户端', path: require('@/assets/img/other/eqCode2.jpg') }
            ]
        }
    },

    methods: {
        initData() {
        },

        //底部导航点击事件
        clickBottomMenu(item) {
            let clickBottomMenuName = item.component
            let currentMenu = this.$store.state.pubInfo.currentMenu
            if (clickBottomMenuName != currentMenu) {
                this.$store.commit('setCurrentMenu', clickBottomMenuName)
                this.reload()
                this.$router.push({name: clickBottomMenuName})
            }
        }
    },
}
</script>

<style lang="less" scoped>
.bottom_container {
    height: 420PX;
    background: #252525;
    display: flex;
    flex-direction: column;
    align-items: center;

    .bottom_topContent {
        height: 290PX;
        width: 1422PX;
        padding: 40PX 240PX 0 240PX;
        // margin-left: 10%;
        .topContent_left,
        .topContent_center,
        .topContent_right {
            height: 230px;
            float: left;
        }

        .topContent_left {
            width: calc(22% - 0rem);
            color: #fff;
            padding: 0px;
            margin-top: 16PX;

            h4 {
                font-size: 16PX;
                margin: 0px;
                font-weight: normal;
            }

            p {
                font-size: 15PX;
                color: #b3b3b3;
                // //letter-spacing: 2px;
            }

            small {
                font-size: 15PX;
                color: #b3b3b3;
                font-weight: normal;
                // //letter-spacing: 1px;
            }
        }

        .topContent_center {
            height: 365PX;
            width: 55%;
            overflow: hidden;
            padding-left: 10PX;

            dl {
                margin-left: 50PX;
                float: left;
                width: 25%;
            }

            dt {
                height: 26PX;
                font-size: 16PX;
                margin-bottom: 15PX;
                color: #fff;
                position: relative;
            }

            dd {
                height: 40PX;
                color: #b2b4b3;
                margin: 0;
                padding: 0;
                font-weight: normal;
            }

            a {
                color: #999;
                font-size: 13PX;
                text-decoration: none;
                line-height: 10PX;
            }

            a:hover {
                color: #2db5a3;
            }
        }

        .topContent_right {
            width: 15%;
            display: flex;

            .topContent_img {
                width: 120PX;
                height: 140PX;
                margin-left: 8PX;
                margin-top: 20px;
                text-align: center;

                img {
                    width: 100PX;
                    height: 100PX;
                }
            }
        }
    }

    .bottom_bottomContent {
        height: 0PX;
        // height: 110px;
        position: relative;
        text-align: center;
        width: 100%;
        padding: 0 240PX;
        background: #252525;
        color: #999;
        line-height: 50PX;
        margin-top: 62PX;

        p {
            font-size: 12PX;
            text-align: center;
            background-color: #252525;
            // //letter-spacing: 2px;
            margin-top: 0.5rem;
            padding-top: 22PX;

            a {
                text-decoration: none;
                color: #999;
            }

            img {
                margin: 0 5PX;
            }
        }
    }

    .bottom_bottomContent::before {
        content: "";
        position: absolute;
        top: 28PX;
        left: 50%;
        width: 1300PX;
        height: 1PX;
        transform: translateX(-50%); /* 水平居中 */
        background-color: #6b6868;
    }
}

/* 小屏幕（小于768 ，大于等于391px） */
@media only screen and (max-width: 768px) and ( min-width: 391px) {
    .bottom_container {
        height: 122rem;
        position: relative;
        // font-size: 3.375rem;
        .bottom_topContent {
            height: 100.125rem;
            padding: 3rem 0rem 0 5rem;
            display: block;
            width: 96%;
            margin: auto;

            .topContent_left {
                height: 21.125rem;
                width: 100%;
                display: inline-block;

                h3 {
                    width: 25%;
                    margin: 2.9rem 2rem 4rem 0rem;
                    float: left;
                    font-size: 3.375rem;
                }

                p {
                    margin-top: 2.25rem;
                }

                .d1 {
                    width: 25%;
                    float: right;
                    margin-left: 0;

                    p {
                        font-size: 2.5rem;
                        // //letter-spacing: 0.825rem;
                    }

                    small {
                        font-size: 2.5rem;
                    }
                }

                .d2 {
                    width: 25%;
                    float: left;
                    margin-left: 8rem;

                    p {
                        font-size: 2.5rem;
                        // //letter-spacing: 1.125rem;
                    }

                    small {
                        font-size: 2.5rem;
                    }
                }
                .d3 {
                    width: 25%;
                    float: left;
                    margin-left: 15rem;

                    p {
                        font-size: 2.5rem;
                        // //letter-spacing: 1.125rem;
                    }

                    small {
                        font-size: 2.5rem;
                    }
                }
            }
        }

        .topContent_center {
            height: 52rem !important;
            width: 111% !important;
            padding-left: 0 !important;

            dl {
                margin-left: 0 !important;
                width: 24.3% !important;
                height: 50rem;

                dt {
                    height: 6.625rem !important;
                    font-size: 3rem !important;
                }

                dd {
                    height: 6rem !important;

                    a {
                        height: 6rem !important;
                        line-height: 3rem !important;
                        //letter-spacing:0rem !important;
                        font-size: 2.4375rem !important;
                    }
                }
            }

            .dl_productService {
                width: 37% !important;
            }

            .dl_cloudMarket {
                // padding-left: 10rem;
                width: 25% !important;
            }
        }

        .topContent_right {
            width: 80% !important;
            height: 26rem !important;
            margin-left: 11rem;

            .topContent_img {
                width: 29.5rem !important;
                height: 26rem !important;
                margin-right: 10.5rem !important;
                bottom: 2rem !important;

                img {
                    width: 17.25rem !important;
                    height: 17.25rem !important;
                }

                small {
                    font-size: 2.8rem;
                }
            }
        }

        .bottom_bottomContent {
            padding-top: 5rem !important;
            height: 40.875rem !important;
            width: 79% !important;

            p {
                font-size: 2rem !important;
            }
        }
    }

}

/* 小屏幕（平板，大于等于 390px） */
@media only screen and (max-width: 1090px) {
    .bottom_container {
        height: 150rem;
        position: relative;
        // font-size: 3.375rem;
        .bottom_topContent {
            height: 115.125rem;
            padding: 3rem 0rem 0 6rem;
            display: block;
            width: 96%;
            margin-bottom: 87px;
            margin-top: 20px;

            .topContent_left {
                height: 37.125rem;
                width: 100%;
                padding-left:3rem ;
                // display: inline-block;

                h4 {
                    width: 40%;
                    margin: 0.9rem 2rem 2rem 8rem;
                    // float: left;
                    font-size: 3.375rem;
                }

                p {
                    margin-top: 2.25rem;
                }

                .d1 {
                    width: 75%;
                    float: left;
                    margin-left: 8rem;
                    margin-bottom: 2rem;


                    p {
                        font-size: 2.5rem;
                        // //letter-spacing: 0.825rem;
                    }

                    small {
                        font-size: 2.5rem;
                    }
                }

                .d2 {
                    width: 75%;
                    // float: left;
                    margin-left: 8rem;

                    p {
                        font-size: 2.5rem;
                        // //letter-spacing: 1.125rem;
                    }

                    small {
                        font-size: 2.5rem;
                    }
                }
                .d3 {
                    width: 62%;
                    // float: left;
                    margin-left: 8rem;
                    margin-top: 2rem;

                    p {
                        font-size: 2.5rem;
                        // //letter-spacing: 1.125rem;
                    }

                    small {
                        font-size: 2.5rem;
                    }
                }
            }
        }

        .topContent_center {
            height: 59rem !important;
            width: 100% !important;
            padding-left: 1rem !important;
            // display: flex;
            // justify-content: center;
            dl {
                margin-left: 11rem !important;
                width: 22.6% !important;
                height: 46rem;
                //   margin: 0 20px;
                dt {
                    height: 6.625rem !important;
                    font-size: 3rem !important;
                }

                dd {
                    height: 6rem !important;

                    a {
                        height: 6rem !important;
                        line-height: 3rem !important;
                        //letter-spacing:0rem !important;
                        font-size: 2.4375rem !important;
                    }
                }
            }

            .dl_productService {
                width: 37% !important;
            }

            .dl_cloudMarket {
                // padding-left: 10rem;
                width: 25% !important;
            }
        }

        .topContent_right {
            width: 80% !important;
            height: 26rem !important;
            margin-left: 11rem;
            margin-top: 8rem;

            .topContent_img {
                width: 29.5rem !important;
                height: 26rem !important;
                // margin-right: 10.5rem !important;
                bottom: 2rem !important;
                margin-top: 5.25rem !important;
                margin-left: -6rem !important;

                img {
                    width: 17.25rem !important;
                    height: 17.25rem !important;
                }

                small {
                    font-size: 2.8rem;
                }
            }
        }

        .bottom_bottomContent {
            padding-top: 5rem !important;
            height: 40.875rem !important;
            width: 79% !important;

            p {
                font-size: 2rem !important;
            }
        }
        .bottom_bottomContent::before {
            content: "";
            position: absolute;
            top: 40PX;
            left: 50%;
            width: 1300PX;
            height: 1PX;
            transform: translateX(-50%); /* 水平居中 */
            background-color: #6b6868;
        }
    }

}
</style>
